export const PDF_PATHS = {
  DOCUMENT_3_REASONS: {
    en: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    de: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_de.pdf",
    fr: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_fr.pdf",
  },
  OF_DATA_SHEET: {
    en: "/pdf/EN_Datasheet_Ootbi_192TB.pdf",
    de: "/pdf/DE_Datasheet_Ootbi_192TB.pdf",
    fr: "/pdf/FR_Datasheet_Ootbi_192TB.pdf",
  },
  ZERO_TRUST: {
    en: "/pdf/Zero_Trust_and_Enterprise_Data_Backup.pdf",
    de: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_de.pdf",
    fr: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_fr.pdf",
  },
} as const;
